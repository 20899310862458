export const START_LOADING = "START_LOADING_BARN";
export const END_LOADING = "END_LOADING_BARN";
export const FETCHING_BARNS = "FETCHING_ALL_BARN";
export const SHOW_DOWNLOAD_POPUP = "SHOW_DOWNLOAD_POPUP";
export const HIDE_DOWNLOAD_POPUP = "HIDE_DOWNLOAD_POPUP";
export const GET_DOWNLOAD_URL = "GET_DOWNLOAD_URL";
export const SHOW_ERROR = "SHOW_ERROR_BARN";
export const SHOW_SUCCESS = "SHOW_SUCCESS_BARN";
export const RESET_ALERT = "RESET_ALERT_BARN";
export const UPDATE_SEARCH = "UPDATE_SEARCH_BARN";
export const UPDATE_BARNS = "UPDATE_ALL_BARN";
export const RESET_SEARCH = "RESET_SEARCH_BARN";
export const RESET_BARN_DATA = "RESET_BARN_DATA";
export const UPDATE_PAGING = "CURRENT_BARN_PAGE";
