export const START_LOADING = "BARN_START_LOADING";
export const END_LOADING = "BARN_END_LOADING";
export const FETCHING_SINGLE_BARN = "BARN_FETCHING_SINGLE_DETAIL";
export const FETCHING_SINGLE_GRAPH = "BARN_FETCHING_SINGLE_GRAPH";
export const RESET_SINGLE_GRAPH = "BARN_RESET_SINGLE_GRAPH";
export const EDIT_BARN_OVERVIEW = "EDIT_BARN_OVERVIEW";
export const UPDATE_BARN_OVERVIEW = "UPDATE_BARN_OVERVIEW";
export const EDIT_CYCLE_DETAILS = "EDIT_CYCLE_DETAILS";
export const UPDATE_CYCLE_DETAILS = "UPDATE_CYCLE_DETAILS";
export const HANDLE_AVERAGE_WEIGHT = "HANDLE_AVERAGE_WEIGHT";
export const UPDATE_AVERAGE_WEIGHT = "UPDATE_AVERAGE_WEIGHT";
export const SHOW_DOWNLOAD_POPUP = "SHOW_DOWNLOAD_POPUP";
export const HIDE_DOWNLOAD_POPUP = "HIDE_DOWNLOAD_POPUP";
export const TRIGGER_DOWNLOAD = "TRIGGER_DOWNLOAD";
export const GET_DOWNLOAD_URL = "GET_DOWNLOAD_URL";
export const SHOW_GRAPH_MODAL = "SHOW_GRAPH_MODAL";
export const CLOSE_GRAPH_MODAL = "CLOSE_GRAPH_MODAL";
export const UPDATE_BARN_VISIBILITY = "UPDATE_BARN_VISIBILITY";
